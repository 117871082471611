import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../molecules/header/header";
import Footer from "../../molecules/footer/footer";

const Layout = props => {
  const { children, title = "Dicar" } = props;
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
    </Helmet>
    <Header />
    <main>
      {children}
    </main>
    <Footer />
    </>
  )
}

export default Layout;