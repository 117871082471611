import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';

import "./header.scss";

const searcherIcons = ["icon-search", "icon-close"];

const Header = () => {
  const data = useStaticQuery(graphql`
		query QUERY_HEADER {
			allContentfulHeader {
        nodes {
          menuOptions {
            label
            href
          }
          logo {
            file {
              fileName
              url
            }
          }
        }
      }
		}
  `);

  const [showMenu, setShowMenu] = useState(false);
  const [searcherIcon, setSearcherIcon] = useState(0);
  const [inputExpanded, setInputExpanded] = useState(false);

  let searchText = useRef(null);

  useEffect(() => {
    const listener = event => {
      if ((event.code === "Enter" || event.code === "NumpadEnter" || event.keyCode === 13) && searchText.current.value) {
        window.location.href = `${window.location.origin}/resultados-busqueda?toSearch=${searchText.current.value.toLowerCase().trim()}`
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [])

  const handleShowMenu = () => setShowMenu(shown => !shown);

  const handleIconClick = async (e) => {
    const iconClass = e.currentTarget.className.split(" ")[1];
    if (iconClass === searcherIcons[0]) {
      await setInputExpanded(true);
      searchText.current.focus();
    } else {
      searchText.current.value = "";
      setInputExpanded(false);
      setSearcherIcon(0);
    }
  }

  const headerInfo = data.allContentfulHeader.nodes[0];

  return (
    <header className="m-header">
      <a className="m-header__logo" href="/">
        <img
          alt="Logo header" 
          src={headerInfo.logo.file.url}
        />
      </a>
      <ul className="m-header__options">
        {headerInfo.menuOptions.map(option => (
          <li 
            key={option.label}
            className="m-header__options__option"
          >
            <a href={option.href}>
              {option.label}
            </a>
          </li>
        ))}
      </ul>
      <div className="m-header__searcher">
        <input
          className={`m-header__searcher__input ${inputExpanded
            ? "m-header__searcher__input--expanded" : ""}`
          }
          type="text"
          ref={searchText}
          onChange={() => setSearcherIcon(searchText?.current?.value ? 1 : 0)}
          onFocus={() => setInputExpanded(true)}
          onBlur={() => searchText?.current?.value ? false : setInputExpanded(false)}
        />
        <i
          className={`m-header__searcher__icon ${searcherIcons[searcherIcon]}`}
          onClick={(e) => handleIconClick(e)}
        />
      </div>
      <div 
        className={`${showMenu ? "icon-close" : "icon-menu"} m-header__menu-icon`}
        onClick={() => handleShowMenu()}
        role="button"
        aria-label="icon-menu"
      />
      {showMenu &&
        <ul className="m-header__mobile-menu">
          {headerInfo.menuOptions.map(option => (
            <li
              key={option.label}
              className="m-header__mobile-menu__option"
            >
              <a href={option.href}>
                {option.label}
                <div className="icon-chevron-right" />
              </a>
            </li>
          ))}
        </ul>
      }
    </header>
  )
}

export default Header;