import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import "./footer.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
		query QUERY_FOOTER {
			allContentfulFooter {
        nodes {
          emails {
            raw
          }
          image {
            file {
              fileName
              url
            }
          }
          phones {
            raw
          }
          socials {
            raw
          }
        }
      }
		}
  `);

  const info = data.allContentfulFooter.nodes[0];

  const phones = JSON.parse(info.phones.raw).content;
  const emails = JSON.parse(info.emails.raw).content;
  const socials = JSON.parse(info.socials.raw).content;

  const getDomainName = (url) => {
    const host = new URL(url).host;
    return host.split(".")[1];
  }

  return (
    <footer className="m-footer">
      <img
        className="m-footer__image-mobile"
        alt="Imagen footer" 
        src={info.image.file.url}
      />
      <div className="m-footer__content">
        <div className="m-footer__image">
          <img 
            alt="Imagen footer" 
            src={info.image.file.url}
          />
        </div>
        <div className="m-footer__section">
          <p className="m-footer__section__title">Teléfonos</p>
          <div className="m-footer__section__content">
            {phones.map(phone => {
              const phoneFormatted = phone.content[0].value 
                .replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "");
              return (
                <a
                  key={phoneFormatted} 
                  className="m-footer__section__content__item"
                  href={`tel: ${phoneFormatted}`}
                >
                  {phone.content[0].value}
                </a>
              )
            })}
          </div>
        </div>
        <div className="m-footer__section">
          <p className="m-footer__section__title">Correos electrónicos</p>
          <div className="m-footer__section__content">
            {emails.map(email => (
              <a
                key={email.content[0].value} 
                className="m-footer__section__content__item"
                href={`mailto: ${email.content[0].value}`}
              >
                {email.content[0].value}
              </a>
            ))}
          </div>
        </div>
        <div className="m-footer__section">
          <p className="m-footer__section__title">
            Síguenos
          </p>
          <div className="m-footer__section__socials">
            {socials.map(social => {
              const socialName = getDomainName(social.content[0].value);
              return (
                <a 
                  key={socialName} 
                  className={`m-footer__socials__social icon-${socialName}`}
                  href={social.content[0].value}
                  target="_blank"
                  rel="noreferrer"
                >
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;